import axios from "axios";
import { agent } from "../utils/agent";

const API_URL = process.env.REACT_APP_API_URL;

const getFavClub = async (user_id) => {
  const response = await axios.get(API_URL + "get-fav-club/" + user_id);
  return response.data;
};

const getPlayingEleven = async (query = "") => {
  const response = await agent.get(
    API_URL + `get-playing-eleven?name=${query}`
  );
  return response.data;
};
const getUserDetail = async (user_id) => {
  const response = await agent.get(API_URL + "get-user-club/" + user_id);
  return response.data;
};
const getUserTeamData = async () => {
  const response = await agent.get(API_URL + "getUserTeamData");
  return response.data;
};

const activatePower = async (data) => {
  const response = await agent.post(API_URL + "activatePower", data);
  return response.data;
};
const saveTeam = async (data) => {
  const response = await agent.post(API_URL + "saveTeam", data);
  return response.data;
};
const revertTrade = async (budgetLeft) => {
  const response = await agent.get(API_URL + "revertTrade");
  return response.data;
};
const tradedPlayerList = async () => {
  const response = await agent.get(API_URL + "tradedPlayerList");
  return response.data;
};
const revertTripleCapPower = async (data) => {
  const response = await agent.post(API_URL + "revertTripleCapPower", data);
  return response.data;
};
const getBonusCardPlayer = async () => {
  const response = await agent.get(API_URL + "getBonusCardPlayer");
  return response.data;
};
const getTripleCapPlayer = async () => {
  const response = await agent.get(API_URL + "getTripleCapPlayer");
  return response.data;
};
const markAsReadTeamAlert = async (alertId) => {
  const response = await agent.get(API_URL + "markAsReadTeamAlert/" + alertId);
  return response.data;
};

const getFixtureSquads = async () => {
  const response = await agent.get(API_URL + "getFixtureSquads");
  return response.data;
};
const MyTeamService = {
  getFavClub,
  getUserDetail,
  getPlayingEleven,
  getUserTeamData,
  activatePower,
  saveTeam,
  revertTrade,
  tradedPlayerList,
  revertTripleCapPower,
  getBonusCardPlayer,
  getTripleCapPlayer,
  markAsReadTeamAlert,
  getFixtureSquads,
};

export default MyTeamService;
