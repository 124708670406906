import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import store from "./Redux/store";
import { ContextProvider } from "./contexts/ContextProvider";
// import "././assets/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

// import "././assets/css/slick.css";
import "././assets/css/custom.css";
import "././assets/css/custom-bkp.css";
import "././assets/css/toastr.min.css";
import { GradientProvider } from "./contexts/GradientContext";

// console.log(<App/>,"=========")
ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GradientProvider>
        <App />
      </GradientProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
